import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
// import { isValidDate } from '../../../utils/functions/isValidDate.js';
import AddIcon from '@mui/icons-material/Add';
import { GridToolbarContainer } from '@mui/x-data-grid-premium';
import CaseNotes from '../../../components/CaseNotes.jsx';
import CaseViewAutocomplete from '../../../components/CaseView/CaseViewAutocomplete.jsx';
import CaseViewFieldsContainer from '../../../components/CaseView/CaseViewFieldsContainer.jsx';
import CaseViewTextField from '../../../components/CaseView/CaseViewTextField.jsx';
import CondensedDataGrid from '../../../components/CondensedDataGrid.jsx';
import { useAuth } from '../../../utils/auth/AuthService.tsx';
import {
  AssignmentTypes,
  NoPronouncement_Other,
  Occupation_Other,
  PronouncerAgency_Other,
  PronouncerLocation_Other,
  Race_Other,
} from '../../../utils/constants/constants.js';
import options from '../../../utils/constants/options.js';
import { roleCharacteristicsCheck } from '../../../utils/functions/roleCharacteristicsCheck.tsx';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import AddressData from '../../../components/AdministrationModules/AddressComponents/AddressData.js';
import { CaseReporterTypesAutocomplete } from '../../../components/CaseReporterTypesAutocomplete.tsx';
import { CaseViewAge } from '../../../components/CaseView/CaseViewAge';
import CaseViewAutocompleteCheckbox from '../../../components/CaseView/CaseViewAutocompleteCheckbox.jsx';
import { CaseViewSameAsAddressField } from '../../../components/CaseView/CaseViewSameAsAddressField';
import IdentificationDialog from '../../../components/IdentificationDialog.jsx';
import InformantDialog from '../../../components/InformantDialog.jsx';
import {
  getAgenciesEndpointFromCaseReporterType,
  useOptions,
} from '../../../utils/api/useOptions.hook.tsx';
import CrudDataGrid from '../../../utils/components/CrudDataGrid/CrudDataGrid.jsx';
import { dateColumnType } from '../../../utils/components/CrudDataGrid/DateColumn.jsx';
import { FormikTextField } from '../../../utils/components/FormikTextField';
import { MEAction } from '../../../utils/constants/MEAction';
// import RecordsView from '../CaseViewModules/RecordsView';
import { useLocation } from 'react-router';
import CaseDocuments from '../../../components/CaseRecords/CaseDocuments';
import CaseViewDateField from '../../../components/CaseView/CaseViewDateField';
import { ActionList } from '../../../components/CaseView/CaseViewDateTimeField.jsx';
import useStickyState from '../../../utils/functions/useStickyState';
import { AddressMap } from './InvestigationsModules/AddressMap.tsx';
import CaseActivityLog from './InvestigationsModules/CaseActivityLog.jsx';
import DiscoveryView from './InvestigationsModules/DiscoveryView.jsx';
import ExamObjectionsView from './InvestigationsModules/ExamObjectionsView.jsx';
import { MEActionView } from './InvestigationsModules/MEActionView';
import SceneExaminedBy from './InvestigationsModules/SceneExaminedBy.jsx';
import { OrganDonationView } from './OrganDonationView';

export default function InvestigationsView() {
  const formik = useFormikContext();
  const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [isInformantDialogOpen, setIsInformantDialogOpen] = useState(false);
  const [isIdentificationDialogOpen, setIsIdentificationDialogOpen] = useState(false);
  const [selectedInformant, setSelectedInformant] = useState([]);
  const [selectedIdentification, setSelectedIdentification] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [informantToDelete, setInformantToDelete] = useState(null);
  const [identificationToDelete, setIdentificationToDelete] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showInformantDeleteError, setShowInformantDeleteError] = useState(false);
  const [pregnancystageOptions, setPregnancyStageOptions] = useState([]);
  const [raceOptions, setRaceOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [occupationOptions, setOccupationOptions] = useState([]);
  const [noPronouncementReasonOptions, setNoPronouncementReasonOptions] = useState([]);
  const [formError, setFormError] = useState(null);
  const { REACT_APP_API_URL } = process.env;
  const { user } = useAuth();
  const hasSystemAdminRole = user?.roleCheck(['d1582600-5f86-49dd-bab7-6f7205bfeffd']);
  const hasMERole = user?.roleCheck(['caef1f91-c392-4b53-a466-a8f418d8d25a']);
  const authorizedToEdit = user.roleCheck([
    'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
    'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
    'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
    '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
    '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
  ]);
  const [showCaseLog, setShowCaseLog] = useState(false);

  // const { state, saveCurrentInnerTab: setSelectedTab } = useCaseViewSaveInnerTab();
  // const selectedTab = useMemo(() => {
  //   return state.Investigations ?? 0;
  // }, [state]);
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useStickyState(
    0,
    'case.investigations.tab',
    location.state.cmscaseid
  );
  const [caseReporter, setCaseReporter] = useState({});
  const [relationshipOptions, setRelationshipOptions] = useState([]);
  const [pronouncerLocationOptions, setPronouncerLocationOptions] = useState([]);
  const [pronouncerAgencyOptions, setPronouncerAgencyOptions] = useState(null);

  const [validationErrors, setValidationErrors] = useState([]);
  const [addressTypeOptions, setAddressTypeOptions] = useState([]);
  const [pastMedicalHistoryOptions, setPastMedicalHistoryOptions] = useState([]);
  const [pastSocialHistoryOptions, setPastSocialHistoryOptions] = useState([]);
  const appTheme = useTheme();

  const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(appTheme.breakpoints.between('md', 'lg'));

  const [showMap, setShowMap] = useState(() => {
    const storedValue = localStorage.getItem('showMap');
    return storedValue === 'true'; // Convert string to boolean
  });

  const [value, setValue] = useState(null);

  // const CaseActivityLog = () => {
  //   const [showCaseLog, setShowCaseLog] = useState(false);
  // }

  const toggleCaseLogVisibility = () => {
    setShowCaseLog(prevShowCaseLog => !prevShowCaseLog);
  };

  const identificationscolumns = [
    {
      field: 'identifiedBy',
      headerName: 'Identified By',
      flex: 1,
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
    },
    {
      field: 'modality',
      headerName: 'Modality',
      flex: 1,
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
    },
    {
      field: 'source',
      headerName: 'Notes',
      flex: 1,
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
    },
    {
      field: 'placeOfIdentification',
      headerName: 'Place Of Identification',
      flex: 1,
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
    },
    {
      field: 'identificationDateTime',
      headerName: 'Date/Time Of ID',
      flex: 1,
      valueFormatter: params => {
        return params.value ? format(new Date(params.value), 'MM/dd/yyyy, HH:mm') : '';
      },
    },
    {
      field: 'identificationOutcome',
      headerName: 'Outcome',
      flex: 1,
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
    },

    {
      field: 'reportedBy',
      headerName: 'Reported By',
      flex: 1,
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
    },

    {
      field: 'meConfirmation',
      headerName: 'Confirmation',
      flex: 1,
      valueGetter: params => {
        const meUser = params.row?.meConfirmationUser?.optionName || '';

        if (params.row?.isAccept === true) {
          return 'Accepted by: ' + meUser;
        } else if (params.row?.isReject === true) {
          return 'Rejected by: ' + meUser;
        }

        return '';
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      editable: false,
      renderCell: params => {
        return (
          <div style={{ display: 'flex' }}>
            <IconButton
              onClick={e => onIdentificationEditClick(e, params.row)}
              size='small'
              color='primary'
              disabled={!authorizedToEdit}
              style={{ justifyContent: 'left', marginRight: '1rem' }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={e => onIdentificationDeleteClick(e, params.row)}
              size='small'
              color='error'
              disabled={!authorizedToEdit}
              style={{ justifyContent: 'left' }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const informantcolumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: params =>
        `${params.row.personLastName || ''}${
          params.row.personLastName ? (params.row.personFirstName ? ', ' : '') : ''
        }${params.row.personFirstName || ''}`,
    },

    {
      field: 'relship',
      headerName: 'Relationship',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: params => {
        return params.value ? params.value.optionName : '';
      },
    },

    {
      field: 'contactNumber',
      headerName: 'Contact',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: params => {
        const contactItem = params.row?.informantAddress?.contactItem;
        if (contactItem && contactItem.length > 0) {
          return contactItem[0].contactItemDetails || '';
        }
        return '';
      },
    },

    {
      field: 'relIcon',
      headerName: '',
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: params => {
        const isNok = params.row.nextOfKin === 1;
        const isCr = params.row.caseReporter === 1;

        return (
          <>
            {isNok && (
              <Chip
                label='NOK'
                color='secondary'
                variant='outlined'
                size='small'
                style={{ marginRight: '4px' }}
              />
            )}
            {isCr && <Chip label='CR' color='primary' variant='outlined' size='small' />}
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: params => {
        return (
          <div>
            <IconButton
              onClick={e => onInformantEditClick(e, params.row)}
              size='small'
              color='primary'
              disabled={!authorizedToEdit}
              style={{ justifyContent: 'left', marginRight: '1rem' }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={e => onInformantDeleteClick(e, params.row)}
              size='small'
              color='error'
              disabled={!authorizedToEdit}
              style={{ justifyContent: 'left' }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  function CustomInformantToolbar() {
    return (
      <GridToolbarContainer>
        <Button color='primary' startIcon={<AddIcon />} onClick={() => onInformantAddClick()}>
          Add Contact
        </Button>
      </GridToolbarContainer>
    );
  }

  function CustomIDToolbar() {
    return (
      <GridToolbarContainer>
        <Button color='primary' startIcon={<AddIcon />} onClick={() => onIdentificationAddClick()}>
          Add Modality
        </Button>
      </GridToolbarContainer>
    );
  }

  const dismissAlert = () => {
    setShowErrorAlert(false);
    setShowInformantDeleteError(false);
  };

  const handleShowMapCheckboxChange = event => {
    const { checked } = event.target;
    setShowMap(checked);
    localStorage.setItem('showMap', checked);
  };

  //Handle Identification
  const onIdentificationAddClick = () => {
    const defaultIdentification = {
      placeOfIdentification: {
        optionSeq: 'E3C6B6FE-AEFF-4E2F-9865-53019AD87B00',
        optionName: 'Scene',
      },
      identificationOutcome: {
        optionSeq: '3A9932DC-8AFE-4E18-9300-7D3CF3FF9645',
        optionName: 'Identification',
      },
      identificationDateTime: format(new Date(), 'yyyy-MM-dd HH:mm'),
    };
    setSelectedIdentification(defaultIdentification);
    setIsIdentificationDialogOpen(true);
  };

  const onIdentificationEditClick = async (e, row) => {
    setSelectedIdentification(row);
    setIsIdentificationDialogOpen(true);
  };

  const onIdentificationDeleteClick = (e, row) => {
    e.stopPropagation();
    setShowConfirmation(true);
    setIdentificationToDelete(row);
  };

  const handleIdentificationRowClick = params => {
    // if (formik.status?.editing) {
    onIdentificationEditClick(params.event, params.row);
    // }
  };

  //Handle Informat
  const onInformantAddClick = () => {
    setSelectedInformant([]);
    setIsInformantDialogOpen(true);
  };

  const onInformantEditClick = async (e, row) => {
    if (row?.informantAddress) {
      setSelectedInformant(row);
    } else {
      const fetchedAddress = await fetchInformantAddress(row?.personSeq);
      setSelectedInformant({ ...row, informantAddress: fetchedAddress });
    }
    // setSelectedContactTab(0);
    setIsInformantDialogOpen(true);
  };

  const handleInformantRowClick = params => {
    // if (formik.status?.editing) {
    onInformantEditClick(params.event, params.row);
    // }
  };

  const onInformantDeleteClick = (e, row) => {
    e.stopPropagation();
    setShowConfirmation(true);
    setInformantToDelete(row);
  };

  const handleInformantSave = informant => {
    let formData = new FormData();
    formData.append('informant', JSON.stringify(informant));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append(
      'decedentPersonSeq',
      formik.values?.caseDemographics?.decedentPerson?.personSeq
    );

    if (user && user.token) {
      fetch(REACT_APP_API_URL + 'updateInformant', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          formik.setFieldValue('caseDemographics.informantList', data);
        })
        .catch(e => {
          alert(e);
        })
        .finally(() => {});
    } else {
      console.log('user or token is null');
    }
    return false;
  };

  const getIdentificationStatusValueCode = identifications => {
    if (identifications?.some(record => record.isAccept === true)) {
      return 'C';
    } else if (identifications?.some(record => record.isAccept === false)) {
      return 'R';
    } else {
      return 'P';
    }
  };

  const handleIdentificationSubmit = async identificationToSubmit => {
    const updatedList = formik.values?.caseDemographics?.identifications?.some(
      identification =>
        identification.identificationDetailSeq === identificationToSubmit.identificationDetailSeq
    )
      ? formik.values?.caseDemographics?.identifications.map(identification =>
          identification.identificationDetailSeq === identificationToSubmit.identificationDetailSeq
            ? identificationToSubmit
            : identification
        )
      : [identificationToSubmit, ...formik.values?.caseDemographics?.identifications];

    formik.setFieldValue('caseDemographics.identifications', updatedList);
    const statusValueCode = getIdentificationStatusValueCode(updatedList);
    handleIdentificationSave(identificationToSubmit, statusValueCode);
    setSelectedIdentification([]);
  };

  const handleIdentificationSave = async (identification, statusValueCode) => {
    let formData = new FormData();
    formData.append('identification', JSON.stringify(identification));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    formData.append('userSeq', user?.userSeq);

    const informant =
      formik.values?.caseDemographics?.informantList?.find(
        informantList => informantList.informantSeq === identification.identifiedBy?.optionSeq
      ) || null;

    formData.append('personSeq', informant?.personSeq);
    formData.append('relshipSeq', informant?.relship?.optionSeq);
    formData.append('statusValueCode', statusValueCode);
    formData.append(
      'decedentPersonSeq',
      formik.values?.caseDemographics?.decedentPerson?.personSeq
    );
    formData.append('isTentative', formik.values?.caseInvestigations?.isTentative);

    if (user && user.token) {
      fetch(REACT_APP_API_URL + 'updateIdentification', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          // fetchIdentifications();
        })
        .catch(e => {
          alert(e);
        })
        .finally(() => {
          if (statusValueCode === 'C') {
            formik.setFieldValue('caseInvestigations.isTentative', false);
          }
          fetchIdentifications();
        });
    } else {
      console.log('user or token is null');
    }
    return false;
  };

  const handleConfirmDelete = async () => {
    setShowConfirmation(false);
    if (informantToDelete) {
      // Check if the informant is associated with any identification
      const isInformantAssociated = formik.values?.caseDemographics?.identifications?.some(
        identification => {
          return (
            identification.identifiedBy.optionSeq === informantToDelete.informantSeq &&
            identification.isActive === 1
          );
        }
      );

      if (isInformantAssociated) {
        setFormError(
          ' The identification is connected to the informant. Before deleting, please change the identifier.'
        );
        setShowInformantDeleteError(true);
      } else {
        const informant = {
          ...informantToDelete,
          isActive: 0,
          isChgInd: 1,
        };

        await handleInformantSave(informant);

        setInformantToDelete(null);
        setFormError(null);
        setShowInformantDeleteError(false);
      }
    }

    if (identificationToDelete) {
      const updatedList = formik.values?.caseDemographics?.identifications?.filter(
        identification =>
          identification.identificationDetailSeq !== identificationToDelete.identificationDetailSeq
      );

      const identificationToSubmit = {
        ...identificationToDelete,
        isActive: 0,
        isChgInd: 1,
      };
      const statusValueCode = getIdentificationStatusValueCode(updatedList);
      handleIdentificationSave(identificationToSubmit, statusValueCode);
      setIdentificationToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setInformantToDelete(null);
    setIdentificationToDelete(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDiscoveryViewChange = updatedFields => {
    formik.setFieldValue('caseInvestigations.discovery', {
      ...formik.values?.caseInvestigations.discovery,
      ...updatedFields,
    });
  };

  const handleMEDetailsChange = updatedFields => {
    formik.setFieldValue('caseInvestigations.autopsyObjection.meDetails', {
      ...formik.values.caseInvestigations.autopsyObjection.meDetails,
      ...updatedFields,
    });
  };

  const handleObjectionChange = updatedData => {
    const existingObjector = (
      formik.values?.caseInvestigations?.autopsyObjection?.objector || []
    ).find(objector => objector?.id === updatedData?.id);

    if (existingObjector) {
      const mergedData = { ...existingObjector, ...updatedData };

      formik.setFieldValue(
        'caseInvestigations.autopsyObjection.objector',
        formik.values.caseInvestigations?.autopsyObjection?.objector.map(objector =>
          objector.id === updatedData.id ? mergedData : objector
        )
      );
    } else {
      formik.setFieldValue('caseInvestigations.autopsyObjection.objector', [
        ...(formik.values.caseInvestigations?.autopsyObjection?.objector || []),
        updatedData,
      ]);
    }
  };

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='caseDemographics.responsive-dialog-title'>{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this row?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
          <Button autoFocus onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const fetchInformantAddress = async personSeq => {
    const response = await fetch(REACT_APP_API_URL + 'GetInformantAddress?PersonSeq=' + personSeq, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };

  const fetchMaritalStatusOptions = async () => {
    fetch(REACT_APP_API_URL + 'getmaritalstatusoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setMaritalStatusOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchStateOptions = async () => {
    fetch(REACT_APP_API_URL + 'getstateoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setStateOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchAddressType = async () => {
    fetch(REACT_APP_API_URL + 'getaddresstypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAddressTypeOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchCounty = async () => {
    fetch(REACT_APP_API_URL + 'getcountyoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        // setCountyOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchCountry = async () => {
    const filterValues = ['39D0E397-AF0F-4572-B605-7B2312FFF516'];

    let url = REACT_APP_API_URL + 'getcountryoptions';
    if (filterValues && filterValues?.length > 0) {
      const filterParam = filterValues
        .map(value => `filter=${encodeURIComponent(value)}`)
        .join('&');
      url += `?${filterParam}`;
    }
    fetch(url, {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        // setCountryOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchContactItemType = async () => {
    fetch(REACT_APP_API_URL + 'getcontactitemtypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        // setContactItemTypeOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchPregnancyStageOptions = async () => {
    fetch(REACT_APP_API_URL + 'getpregnancystageoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPregnancyStageOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchRaceOptions = async () => {
    fetch(REACT_APP_API_URL + 'getraceoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setRaceOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchGenderOptions = async () => {
    fetch(REACT_APP_API_URL + 'getgenderoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setGenderOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchOccupationOptions = async () => {
    fetch(REACT_APP_API_URL + 'getoccupationoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setOccupationOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchNoPronouncementReasonOptions = async () => {
    fetch(REACT_APP_API_URL + 'getnopronouncementreason', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setNoPronouncementReasonOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchPastMedicalHistoryOptions = () => {
    fetch(REACT_APP_API_URL + 'getmedicalconditiontypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPastMedicalHistoryOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchIdentifierRelship = async () => {
    fetch(REACT_APP_API_URL + 'getidentifierrelationshipoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setRelationshipOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchIdentifications = async () => {
    const response = await fetch(
      REACT_APP_API_URL + 'GetIdentifications?caseSeq=' + formik.values?.caseSummary?.caseSeq,
      {
        method: 'GET',
      }
    );

    if (response.status === 204) {
      return [];
    }

    const data = await response.json();
    formik.setFieldValue('caseDemographics.identifications', data ?? []);
  };

  const fetchInformantList = async () => {
    const response = await fetch(
      REACT_APP_API_URL + 'GetInformants?caseSeq=' + formik.values?.caseSummary?.caseSeq,
      {
        method: 'GET',
      }
    );
    if (response.status === 204) {
      return [];
    }

    const data = await response.json();
    formik.setFieldValue('caseDemographics.informantList', data);
  };

  // useEffect(() => {
  //   if (
  //     !formik.values?.caseDemographics?.informantList ||
  //     formik.values?.caseDemographics?.informantList?.length === 0
  //   ) {
  //     fetchInformantList();
  //   }
  // }, [formik.status.editing]);

  useEffect(() => {
    fetchMaritalStatusOptions();
    fetchStateOptions();
    fetchAddressType();
    fetchPastMedicalHistoryOptions();
    // fetchCounty();
    // fetchCountry();
    // fetchContactItemType();
    fetchPregnancyStageOptions();
    fetchRaceOptions();
    fetchGenderOptions();
    fetchOccupationOptions();
    fetchIdentifierRelship();
  }, []);

  useEffect(() => {
    if (formik.values?.caseDemographics?.pronouncer?.isNoPronouncement === true) {
      fetchNoPronouncementReasonOptions();
    }
  }, [formik.values?.caseDemographics?.pronouncer?.isNoPronouncement]);

  const filteredInformantList =
    formik.values?.caseDemographics?.informantList?.filter(informant => informant.isActive === 1) ??
    [];

  const filteredIdentifications =
    formik.values?.caseDemographics?.identifications?.filter(
      identification => identification.isActive === 1
    ) ?? [];

  const loadReporterContact = async caseReporter => {
    try {
      const fetchedAddress = await fetchInformantAddress(caseReporter.personSeq);
      const updatedCaseReporter = {
        ...caseReporter,
        informantAddress: fetchedAddress,
      };
      setCaseReporter(updatedCaseReporter);

      const updatedInformantList = formik.values.caseDemographics.informantList.map(informant => {
        if (informant.personSeq === caseReporter.personSeq) {
          return updatedCaseReporter;
        }
        return informant;
      });
      formik.setFieldValue('caseDemographics.informantList', updatedInformantList);
    } catch (error) {
      // Handle the error
    }
  };

  useEffect(() => {
    //caller details
    const reporter = filteredInformantList?.find(
      informant =>
        informant.isActive === 1 &&
        informant.personSeq?.toLowerCase() ===
          formik.values?.caseInvestigations?.caseReporterSeq?.toLowerCase()
    );

    if (reporter) {
      setCaseReporter(reporter);
      if (!reporter.informantAddress) {
        loadReporterContact(reporter);
      }
    }
  }, [formik.values?.caseDemographics?.informantList]);

  const handleCaseReporterChange = (field, value) => {
    console.log(field);
    console.log(value);

    const personSeq = formik.values?.caseInvestigations?.caseReporterSeq;
    if (personSeq) {
      const updatedInformantList = formik.values?.caseDemographics?.informantList?.map(
        informant => {
          if (informant?.personSeq?.toLowerCase() === personSeq?.toLowerCase()) {
            let updatedInformant = { ...informant, isChgInd: 1 };
            if (field === 'contactItemDetails') {
              if (
                !updatedInformant?.informantAddress?.contactItem ||
                !updatedInformant?.informantAddress?.contactItem?.length
              ) {
                updatedInformant.informantAddress.contactItem = [
                  {
                    contactItemDetails: value,
                    contactItemTypeSeq: '176240F7-30A9-40DD-AF0C-304226E1BFEA',
                  },
                ];
              } else {
                updatedInformant.informantAddress.contactItem[0].contactItemDetails = value;
              }
            } else {
              updatedInformant[field] = value;
            }

            if (field === 'relship') {
              updatedInformant.nextOfKin = value?.isNOKRelship ? 1 : 0;
            }
            setCaseReporter(updatedInformant);
            return updatedInformant;
          }
          return informant;
        }
      );
      formik.setFieldValue('caseDemographics.informantList', updatedInformantList);
    }
  };

  const [pronouncementDateError, setPronouncementDateError] = useState(null);

  const caseReporterAgencyEndpoint = useMemo(() => {
    return getAgenciesEndpointFromCaseReporterType(
      formik.values.caseReporterSummary?.caseReporterType?.optionSeq || null
    );
  }, [formik.values.caseReporterSummary?.caseReporterType]);

  const { options: agencyOptions, loading: agencyOptionsLoading } = useOptions(
    caseReporterAgencyEndpoint,
    { jdxSeq: formik.values.caseSummary?.jdxSeq }
  );

  const handleAgencyChange = (e, v) => {
    formik.setFieldValue('caseReporterSummary.agency', v);
  };

  useEffect(() => {
    console.log(formik.values.caseReporterSummary);
  }, [formik.values.caseReporterSummary]);

  useEffect(() => {
    if (formik.values?.caseDemographics?.isDeceased === null) {
      formik.setFieldValue('caseDemographics.isDeceased', true);
    }
  }, [formik.values.caseDemographics?.isDeceased]);

  const fetchPronouncerAgencyOptions = async () => {
    fetch(REACT_APP_API_URL + 'getpronounceragencyoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPronouncerAgencyOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchPronouncerLocationOptions = async () => {
    fetch(REACT_APP_API_URL + 'getpronouncerlocationoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPronouncerLocationOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  useEffect(() => {
    const isTeleMetryUsed = formik.values?.caseDemographics?.pronouncer?.isTeleMetryUsed;
    if (isTeleMetryUsed === true || isTeleMetryUsed === 'true') {
      fetchPronouncerAgencyOptions();
    } else if (isTeleMetryUsed === false || isTeleMetryUsed === 'false') {
      fetchPronouncerLocationOptions();
    }
  }, [formik.values.caseDemographics?.pronouncer?.isTeleMetryUsed]);

  useEffect(() => {
    if (
      formik.values.caseDemographics?.pronouncer?.pronouncerLocation?.optionSeq !==
      PronouncerLocation_Other
    ) {
      formik.setFieldValue('caseDemographics.pronouncer.otherPronouncerLocation', '');
    }
  }, [formik.values.caseDemographics?.pronouncer?.pronouncerLocation]);

  useEffect(() => {
    if (
      formik.values.caseDemographics?.pronouncer?.pronouncerAgency?.optionSeq !==
      PronouncerAgency_Other
    ) {
      formik.setFieldValue('caseDemographics.pronouncer.otherPronouncerAgency', '');
    }
  }, [formik.values.caseDemographics?.pronouncer?.pronouncerAgency]);

  const createMedicationHistory = newRow => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);
    fetch(REACT_APP_API_URL + 'createmedicationhistory', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updateMedicationHistory = updatedRow => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));

    fetch(REACT_APP_API_URL + 'updatemedicationhistory', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteMedicationHistory = id => {
    fetch(REACT_APP_API_URL + `deletemedicationhistory/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const medicationHistoryColumns = [
    {
      field: 'medicationName',
      headerName: 'Medication',
      headerAlign: 'left',
      align: 'left',
      editable: true,
      // editable: formik?.status?.editing,
      flex: 2,
      width: 200,
      type: 'string',
    },
    {
      field: 'medicationPrescribedDate',
      headerName: 'Date Prescribed',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 80,
      ...dateColumnType,
      editable: true,
      // editable: formik?.status?.editing,
      valueGetter: params => {
        if (!params?.value) return undefined;
        if (params?.value === 'Invalid Date') {
          return undefined;
        }
        return new Date(params.value);
      },
    },
    {
      field: 'medicationQuantity',
      headerName: 'Quantity',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 80,
      editable: true,
      // editable: formik?.status?.editing,
      type: 'string',
    },
    {
      field: 'medicationComments',
      headerName: 'Comments',
      headerAlign: 'left',
      align: 'left',
      flex: 2,
      width: 100,
      editable: true,
      // editable: formik?.status?.editing,
      type: 'string',
    },
  ];

  const Selected_ME_Action = useMemo(() => {
    return formik.values?.caseInvestigations?.meAction?.optionSeq?.toUpperCase() || '';
  }, [formik.values?.caseInvestigations?.meAction?.optionSeq]);

  return (
    <form>
      {showInformantDeleteError && (
        <div style={{ marginBottom: '1rem' }}>
          <Alert severity='error' isOpen={showInformantDeleteError} onClose={dismissAlert}>
            {formError}
          </Alert>
        </div>
      )}

      <FormikTextField
        label='MLDI Synopsis'
        formikField='caseInvestigations.synopsis'
        authorizedToEdit={r => [
          r.Medical_Examiner,
          r.Systems_Admin,
          r.Investigator,
          r.Historicdataentryonly,
          r.Historic_Dataentryonly,
          r.Administrative_Assistant,
        ]}
        multiline={true}
      />

      {/* <CardHeader>
        <strong>INVESTIGATIONS</strong>
        
      </CardHeader> */}
      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{
          marginBottom: 2,
          position: 'sticky',
          top: 0,
          zIndex: 1001,
          backgroundColor: appTheme.palette.background.default,
        }}
        variant='scrollable'
        scrollButtons='auto'
        allowScrollButtonsMobile
      >
        <Tab
          label='General'
          sx={{
            minWidth: 'auto',
            padding: '0px 8px',
            border: '0px solid',
            borderRadius: '2px',
            fontWeight: 'bold',
          }}
        />
        <Tab
          label='NOK / Identifications'
          sx={{
            minWidth: 'auto',
            padding: '0px 8px',
            border: '0px solid',
            borderRadius: '2px',
            fontWeight: 'bold',
          }}
        />
        <Tab
          label='Discovery'
          sx={{
            minWidth: 'auto',
            padding: '0px 8px',
            border: '0px solid',
            borderRadius: '2px',
            fontWeight: 'bold',
          }}
        />
        <Tab
          label='Documents'
          sx={{
            minWidth: 'auto',
            padding: '0px 8px',
            border: '0px solid',
            borderRadius: '2px',
            fontWeight: 'bold',
          }}
        />
        <Tab
          label='Organ Donation'
          sx={{
            minWidth: 'auto',
            padding: '0px 8px',
            border: '0px solid',
            borderRadius: '2px',
            fontWeight: 'bold',
          }}
        />
        <Tab
          label='Autopsy Obj'
          sx={{
            minWidth: 'auto',
            padding: '0px 8px',
            border: '0px solid',
            borderRadius: '2px',
            fontWeight: 'bold',
          }}
        />
        <Tab
          label='Case Notes'
          sx={{
            minWidth: 'auto',
            padding: '0px 8px',
            border: '0px solid',
            borderRadius: '2px',
            fontWeight: 'bold',
          }}
        />
      </Tabs>

      {selectedTab === 0 && (
        // <CaseViewFieldsContainer title='Demographic Information'></CaseViewFieldsContainer>
        <CaseViewFieldsContainer>
          <Grid item xs={12}>
            <FormControlLabel
              disabled={!formik.status.editing || !authorizedToEdit}
              checked={Boolean(formik.values?.caseInvestigations?.isTentative)}
              onChange={e =>
                formik.setFieldValue('caseInvestigations.isTentative', e.target.checked)
              }
              control={<Checkbox />}
              label={<span style={{ fontSize: '0.75rem' }}>Tentative</span>} // Adjust font size here
              style={{ marginRight: '16px' }}
            />
            <FormControlLabel
              disabled={!formik.status.editing || !authorizedToEdit}
              checked={Boolean(
                formik.values?.caseInvestigations?.deathInvestigationAdditionalInformation
                  ?.isUnidentified
              )}
              onChange={e =>
                formik.setFieldValue(
                  'caseInvestigations.deathInvestigationAdditionalInformation.isUnidentified',
                  e.target.checked
                )
              }
              control={<Checkbox />}
              label={<span style={{ fontSize: '0.75rem' }}>Unidentified</span>} // Adjust font size here
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
            <Typography variant='subtitle1' color='gray'>
              <strong>DECEDENT DETAILS</strong>
            </Typography>
          </Grid>

          <CaseViewTextField
            xs={12}
            sm={4}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.decedentPerson.lastName'
            label='Decendent Last Name'
          />

          <CaseViewTextField
            xs={12}
            sm={4}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.decedentPerson.firstName'
            label='Decedent First Name'
          />

          <CaseViewTextField
            xs={12}
            sm={1.5}
            md={0.7}
            lg={0.7}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.decedentPerson.suffix'
            label='Suffix'
          />

          <CaseViewTextField
            xs={12}
            sm={2.5}
            md={1.3}
            lg={1.3}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.decedentPerson.middleName'
            label='Middle Name'
          />
          <Grid item xs={12} sm={4} md={2}>
            <FormikTextField
              label='AKA'
              formikField='caseInvestigations.decedentAka'
              authorizedToEdit={r => [
                r.Medical_Examiner,
                r.Systems_Admin,
                r.Investigator,
                r.Historicdataentryonly,
                r.Historic_Dataentryonly,
                r.Administrative_Assistant,
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disabled={!formik.status.editing || !authorizedToEdit}
                name='caseDemographics.pronouncer.pronounciationDateTime'
                label='Pronouncement Date'
                value={
                  formik.values?.caseDemographics?.pronouncer?.pronounciationDateTime
                    ? dayjs(formik.values?.caseDemographics?.pronouncer?.pronounciationDateTime) ||
                      null
                    : null
                }
                onChange={date => {
                  const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                  formik.setFieldValue(
                    'caseDemographics.pronouncer.pronounciationDateTime',
                    localDate
                  );
                }}
                // disableFuture={true}
                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                ampm={false}
                slots={{
                  actionBar: ActionList,
                }}
                slotProps={{
                  field: { clearable: true },
                  textField: { id: 'caseDemographics.pronouncer.pronounciationDateTime' },
                }}
                sx={{
                  minWidth: '100%',
                }}
              />
            </LocalizationProvider>
          </Grid>

          <CaseViewDateField
            xs={12}
            sm={4}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.dateOfBirth'
            label='Date of Birth'
          />

          <Grid item xs={12} sm={4} md={2}>
            <CaseViewAge />
          </Grid>

          <CaseViewTextField
            xs={12}
            sm={4}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.ssn'
            label='SSN'
          />
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseDemographics.gender'
              name='caseDemographics.gender'
              options={genderOptions}
              value={formik.values?.caseDemographics?.gender}
              getOptionLabel={option => option.optionName}
              onChange={(e, value) => formik.setFieldValue('caseDemographics.gender', value)}
              fullWidth
              renderInput={params => <TextField {...params} label='Gender' placeholder='Gender' />}
            />
          </Grid>

          <CaseViewAutocomplete
            xs={12}
            sm={4}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.race'
            options={raceOptions}
            label='Race'
          />

          {formik.values?.caseDemographics?.race != null &&
            formik.values?.caseDemographics?.race?.optionSeq === Race_Other && (
              <CaseViewTextField
                xs={12}
                sm={4}
                md={2}
                authorizedToEdit={authorizedToEdit}
                fieldName='caseDemographics.otherRace'
                label='Other Race'
              />
            )}
          <CaseViewAutocomplete
            xs={12}
            sm={4}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.maritalStatus'
            options={maritalStatusOptions}
            label='Marital Status'
          />

          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              disabled={!formik.status.editing || !authorizedToEdit}
              id='caseDemographics.pregnancyStatus'
              options={pregnancystageOptions}
              value={
                formik.values != null
                  ? pregnancystageOptions.find(
                      option =>
                        option.optionSeq === formik.values?.caseDemographics?.pregnancyStatus
                    ) || null
                  : null
              }
              onChange={(e, value) =>
                formik.setFieldValue(
                  'caseDemographics.pregnancyStatus',
                  value ? value.optionSeq : null
                )
              }
              getOptionLabel={option => option.optionName}
              renderInput={params => <TextField {...params} label='Pregnancy Status' />}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                width: '100%',
              }}
            >
              <Autocomplete
                disabled={!formik.status.editing || !authorizedToEdit}
                id='caseDemographics.veteranStatus'
                options={options}
                value={
                  formik.values != null
                    ? options.find(
                        option => option.value === formik.values?.caseDemographics?.veteranStatus
                      ) || null
                    : null
                }
                onChange={(e, value) =>
                  formik.setFieldValue('caseDemographics.veteranStatus', value ? value.value : null)
                }
                getOptionLabel={option => option.optionName}
                renderInput={params => <TextField {...params} label='Veteran Status' />}
              />
              {(!formik?.status?.editing || !authorizedToEdit) && (
                <Box
                  onClick={() =>
                    formik.setStatus({
                      ...formik.status,
                      disabledClickCount: formik.status?.disabledClickCount + 1,
                    })
                  }
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
          </Grid>

          <CaseViewAutocomplete
            xs={12}
            sm={4}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.occupation'
            options={occupationOptions}
            label='Occupation'
          />

          {formik.values?.caseDemographics?.occupation != null &&
            formik.values?.caseDemographics?.occupation?.optionSeq === Occupation_Other && (
              <CaseViewTextField
                xs={12}
                sm={4}
                md={2}
                authorizedToEdit={authorizedToEdit}
                fieldName='caseDemographics.otherOccupation'
                label='Other Occupation'
              />
            )}

          <CaseViewTextField
            xs={12}
            sm={4}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.privatePhysician.lastName'
            label='Personal Physician Last Name'
          />
          <CaseViewTextField
            xs={12}
            sm={4}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.privatePhysician.firstName'
            label='Personal Physician First Name'
          />

          <CaseViewTextField
            xs={12}
            sm={4}
            md={1}
            lg={0.7}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.privatePhysician.suffix'
            label='Suffix'
          />

          <CaseViewTextField
            xs={12}
            sm={4}
            md={2}
            lg={1.3}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.privatePhysician.middleName'
            label='Personal Physician Middle Name'
          />

          <CaseViewTextField
            xs={12}
            sm={4}
            md={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseDemographics.privatePhysician.contactNumber'
            label='Personal Physician Number'
          />
          <br />
          <Grid item xs={12} sm={4} md={2}>
            <FormControl component='fieldset'>
              <FormLabel>Originally Reported as Deceased? </FormLabel>
              <RadioGroup
                name='caseDemographics.isDeceased'
                value={formik.values?.caseDemographics?.isDeceased ?? null}
                onChange={e => {
                  const value = e.target.value;
                  formik.setFieldValue('caseDemographics.isDeceased', value);
                  if (value === 'false') {
                    formik.setFieldValue('caseDemographics.pronouncer.isNoPronouncement', true);
                  } else {
                    formik.setFieldValue(
                      'caseDemographics.pronouncer.isNoPronouncement',
                      formik.values?.caseDemographics?.pronouncer?.isNoPronouncement
                    );
                  }
                }}
                row
              >
                <FormControlLabel
                  disabled={!formik.status.editing || !authorizedToEdit}
                  value={true}
                  control={<Radio />}
                  label='Yes'
                />
                <FormControlLabel
                  disabled={!formik.status.editing || !authorizedToEdit}
                  value={false}
                  control={<Radio />}
                  label='No'
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {(formik.values?.caseDemographics?.isDeceased === false ||
            formik.values?.caseDemographics?.isDeceased === 'false') && (
            <CaseViewTextField
              xs={12}
              sm={8}
              md={4}
              authorizedToEdit={authorizedToEdit}
              fieldName='caseDemographics.deceasedReason'
              label='Reason'
            />
          )}

          <Grid item xs={12}>
            <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
            <Typography variant='subtitle1' color='gray'>
              <strong>CASE REPORTER</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                width: '100%',
              }}
            >
              <TextField
                id='caseReporter.personLastName'
                fullWidth={true}
                disabled={!formik.status.editing || !authorizedToEdit}
                value={caseReporter.personLastName || ''}
                onChange={e => handleCaseReporterChange('personLastName', e.target.value)}
                label='Caller Last Name'
              />
              {(!formik?.status?.editing || !authorizedToEdit) && (
                <Box
                  onClick={() =>
                    formik.setStatus({
                      ...formik.status,
                      disabledClickCount: formik.status?.disabledClickCount + 1,
                    })
                  }
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                width: '100%',
              }}
            >
              <TextField
                id='caseReporter.personFirstName'
                fullWidth={true}
                disabled={!formik.status.editing || !authorizedToEdit}
                value={caseReporter.personFirstName || ''}
                onChange={e => handleCaseReporterChange('personFirstName', e.target.value)}
                label='Caller First Name'
              />
              {(!formik?.status?.editing || !authorizedToEdit) && (
                <Box
                  onClick={() =>
                    formik.setStatus({
                      ...formik.status,
                      disabledClickCount: formik.status?.disabledClickCount + 1,
                    })
                  }
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={1.5} md={1} xl={0.7}>
            <TextField
              id='caseReporter.suffix'
              fullWidth={true}
              disabled={!formik.status.editing || !authorizedToEdit}
              value={caseReporter.suffix || ''}
              onChange={e => handleCaseReporterChange('suffix', e.target.value)}
              label='Suffix'
            />
          </Grid>

          <Grid item xs={12} sm={2.5} md={2} xl={1.3}>
            <TextField
              id='caseReporter.personMiddleName'
              fullWidth={true}
              disabled={!formik.status.editing || !authorizedToEdit}
              value={caseReporter.personMiddleName || ''}
              onChange={e => handleCaseReporterChange('personMiddleName', e.target.value)}
              label='Caller MiddleName'
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2} lg={2}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                width: '100%',
              }}
            >
              <Autocomplete
                disabled={!formik.status.editing || !authorizedToEdit}
                id='caseReporter.relship'
                options={relationshipOptions}
                value={caseReporter.relship || null}
                onChange={(e, value) => handleCaseReporterChange('relship', value ? value : null)}
                getOptionLabel={option => option.optionName}
                renderInput={params => <TextField {...params} label='Relationship' />}
              />
              {(!formik?.status?.editing || !authorizedToEdit) && (
                <Box
                  onClick={() =>
                    formik.setStatus({
                      ...formik.status,
                      disabledClickCount: formik.status?.disabledClickCount + 1,
                    })
                  }
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                width: '100%',
              }}
            >
              <TextField
                id='caseReporter.contactItemDetails'
                fullWidth={true}
                disabled={!formik.status.editing || !authorizedToEdit}
                value={
                  caseReporter?.informantAddress?.contactItem
                    ? caseReporter?.informantAddress?.contactItem[0]?.contactItemDetails || ''
                    : ''
                }
                onChange={e => handleCaseReporterChange('contactItemDetails', e.target.value)}
                label='Caller Number'
              />
              {(!formik?.status?.editing || !authorizedToEdit) && (
                <Box
                  onClick={() =>
                    formik.setStatus({
                      ...formik.status,
                      disabledClickCount: formik.status?.disabledClickCount + 1,
                    })
                  }
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={4} lg={3}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                width: '100%',
              }}
            >
              <CaseReporterTypesAutocomplete
                disabled={!formik.status.editing || !authorizedToEdit}
                value={formik.values.caseReporterSummary?.caseReporterType}
                label='Type of Facility'
                onChange={(e, v) => {
                  if (v === null) {
                    formik.setFieldValue('caseReporterSummary.caseReporterType', null);
                    formik.setFieldValue('caseReporterSummary.agency', null);
                    return;
                  }

                  if (
                    formik.values.caseReporterSummary?.caseReporterType?.optionSeq?.toLowerCase() !==
                    v?.optionSeq?.toLowerCase()
                  ) {
                    formik.setFieldValue('caseReporterSummary.caseReporterType', v);
                    formik.setFieldValue('caseReporterSummary.agency', null);
                    return;
                  }
                }}
              />
              {(!formik?.status?.editing || !authorizedToEdit) && (
                <Box
                  onClick={() =>
                    formik.setStatus({
                      ...formik.status,
                      disabledClickCount: formik.status?.disabledClickCount + 1,
                    })
                  }
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={4} lg={3}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                width: '100%',
              }}
            >
              {formik.values.caseReporterSummary?.caseReporterType?.optionSeq?.toLowerCase() ===
              '25439ade-f997-451a-9122-2c904dad52d9' ? (
                <TextField
                  fullWidth={true}
                  disabled={!formik.status.editing || !authorizedToEdit}
                  value={formik.values.caseReporterSummary.agencyOther || ''}
                  onChange={e =>
                    formik.setFieldValue('caseReporterSummary.agencyOther', e.target.value)
                  }
                  required
                  label='Other Agency/Facility Name'
                />
              ) : (
                <Autocomplete
                  required
                  disabled={
                    !formik.status.editing ||
                    !authorizedToEdit ||
                    agencyOptionsLoading ||
                    formik.values.caseReporterSummary.caseReporterType === null
                  }
                  options={agencyOptions}
                  value={formik.values.caseReporterSummary.agency}
                  loading={agencyOptionsLoading}
                  isOptionEqualToValue={(o, v) =>
                    o?.optionSeq?.toLowerCase() === v?.optionSeq?.toLowerCase()
                  }
                  getOptionLabel={o => o.optionName.toUpperCase()}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {agencyOptionsLoading ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      label='Name of Facility'
                    />
                  )}
                  onChange={handleAgencyChange}
                />
              )}
              {(!formik?.status?.editing || !authorizedToEdit) && (
                <Box
                  onClick={() =>
                    formik.setStatus({
                      ...formik.status,
                      disabledClickCount: formik.status?.disabledClickCount + 1,
                    })
                  }
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
          </Grid>

          <CaseViewTextField
            xs={12}
            md={4}
            lg={3}
            xl={2}
            authorizedToEdit={authorizedToEdit}
            fieldName='caseInvestigations.medicalRecordNo'
            label='Medical Record Number'
          />

          <Grid item xs={12}>
            {/* <Paper variant='outlined' sx={{ p: 2 }}> */}
            <MEActionView />
            {/* </Paper> */}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
            <Typography variant='subtitle1' color='gray'>
              <strong>PRONOUNCEMENT DETAILS</strong>
            </Typography>
          </Grid>

          <Grid container spacing={2} style={{ marginLeft: '2px' }}>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                label={<Typography variant='body2'>No Pronouncement</Typography>}
                disabled={!formik.status.editing || !authorizedToEdit}
                control={
                  <Checkbox
                    id='caseDemographics.pronouncer.isNoPronouncement'
                    name='caseDemographics.pronouncer.isNoPronouncement'
                    checked={formik.values?.caseDemographics?.pronouncer?.isNoPronouncement}
                    value={formik.values?.caseDemographics?.pronouncer?.isNoPronouncement}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.isNoPronouncement && Boolean(formik.errors.isNoPronouncement)
                    }
                    helperText={formik.touched.isNoPronouncement && formik.errors.isNoPronouncement}
                  />
                }
              />
            </Grid>

            {formik.values?.caseDemographics?.pronouncer != null &&
              formik.values?.caseDemographics?.pronouncer?.isNoPronouncement === true && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      disabled={!formik.status.editing || !authorizedToEdit}
                      id='caseDemographics.pronouncer.noPronouncementSeq'
                      options={noPronouncementReasonOptions}
                      value={
                        formik.values?.caseDemographics?.pronouncer != null
                          ? noPronouncementReasonOptions.find(
                              option =>
                                option.optionSeq ===
                                formik.values?.caseDemographics?.pronouncer?.noPronouncementSeq
                            ) || null
                          : null
                      }
                      onChange={(e, value) =>
                        formik.setFieldValue(
                          'caseDemographics.pronouncer.noPronouncementSeq',
                          value ? value.optionSeq : null
                        )
                      }
                      getOptionLabel={option => option.optionName}
                      renderInput={params => (
                        <TextField {...params} label='Reason For No Pronouncement' />
                      )}
                    />
                  </Grid>

                  {formik.values?.caseDemographics?.pronouncer != null &&
                    formik.values?.caseDemographics?.pronouncer?.noPronouncementSeq ===
                      NoPronouncement_Other && (
                      <CaseViewTextField
                        xs={12}
                        sm={6}
                        md={3}
                        authorizedToEdit={authorizedToEdit}
                        fieldName='caseDemographics.pronouncer.otherNoPronouncement'
                        label='Other Pronouncement Reason'
                      />
                    )}
                </>
              )}
          </Grid>

          <Grid item xs={12}>
            <Collapse
              in={!Boolean(formik.values?.caseDemographics?.pronouncer?.isNoPronouncement)}
              unmountOnExit={false}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      disabled={!formik.status.editing || !authorizedToEdit}
                      name='caseDemographics.pronouncer.pronounciationDateTime'
                      label='Pronouncement Date'
                      value={
                        formik.values?.caseDemographics?.pronouncer?.pronounciationDateTime
                          ? dayjs(
                              formik.values?.caseDemographics?.pronouncer?.pronounciationDateTime
                            ) || null
                          : null
                      }
                      onChange={date => {
                        const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                        formik.setFieldValue(
                          'caseDemographics.pronouncer.pronounciationDateTime',
                          localDate
                        );
                      }}
                      // disableFuture={true}
                      timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                      ampm={false}
                      slots={{
                        actionBar: ActionList,
                      }}
                      slotProps={{
                        field: { clearable: true },
                        textField: { id: 'caseDemographics.pronouncer.pronounciationDateTime' },
                      }}
                      sx={{
                        minWidth: '100%',
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <CaseViewTextField
                  xs={12}
                  sm={4}
                  md={2}
                  authorizedToEdit={authorizedToEdit}
                  fieldName='caseDemographics.pronouncer.lastName'
                  label='Pronouncer Last Name'
                />

                <CaseViewTextField
                  xs={12}
                  sm={4}
                  md={2}
                  authorizedToEdit={authorizedToEdit}
                  fieldName='caseDemographics.pronouncer.firstName'
                  label='Pronouncer First Name'
                />

                <CaseViewTextField
                  xs={12}
                  sm={4}
                  md={1}
                  lg={0.7}
                  authorizedToEdit={authorizedToEdit}
                  fieldName='caseDemographics.pronouncer.suffix'
                  label='Suffix'
                />

                <CaseViewTextField
                  xs={12}
                  sm={4}
                  md={2}
                  lg={1.3}
                  authorizedToEdit={authorizedToEdit}
                  fieldName='caseDemographics.pronouncer.middleName'
                  label='Pronouncer Middle Name'
                />

                <CaseViewTextField
                  xs={12}
                  sm={4}
                  md={2}
                  authorizedToEdit={authorizedToEdit}
                  fieldName='caseDemographics.pronouncer.phoneNo'
                  label='Phone Number'
                />

                <CaseViewTextField
                  xs={12}
                  sm={4}
                  md={2}
                  authorizedToEdit={authorizedToEdit}
                  fieldName='caseDemographics.pronouncer.licenseNo'
                  label='License No'
                />

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={2}>
                      <FormControl component='fieldset'>
                        <FormLabel>Telemetry used? </FormLabel>
                        <RadioGroup
                          name='caseDemographics.pronouncer.isTeleMetryUsed'
                          value={
                            formik.values?.caseDemographics?.pronouncer?.isTeleMetryUsed ?? null
                          }
                          onChange={e => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              'caseDemographics.pronouncer.isTeleMetryUsed',
                              value === '' ? null : value
                            );
                            if (value === 'true') {
                              formik.setFieldValue(
                                'caseDemographics.pronouncer.pronouncerLocation',
                                null
                              );
                              formik.setFieldValue(
                                'caseDemographics.pronouncer.otherPronouncerLocation',
                                ''
                              );
                            } else if (value === 'false') {
                              formik.setFieldValue(
                                'caseDemographics.pronouncer.pronouncerAgency',
                                null
                              );
                              formik.setFieldValue(
                                'caseDemographics.pronouncer.otherPronouncerAgency',
                                ''
                              );
                            } else {
                              formik.setFieldValue(
                                'caseDemographics.pronouncer.pronouncerLocation',
                                null
                              );
                              formik.setFieldValue(
                                'caseDemographics.pronouncer.pronouncerAgency',
                                null
                              );
                              formik.setFieldValue(
                                'caseDemographics.pronouncer.otherPronouncerLocation',
                                ''
                              );
                              formik.setFieldValue(
                                'caseDemographics.pronouncer.otherPronouncerAgency',
                                ''
                              );
                            }
                          }}
                          row
                        >
                          <FormControlLabel
                            disabled={!formik.status.editing || !authorizedToEdit}
                            value={true}
                            control={<Radio />}
                            label='Yes'
                          />
                          <FormControlLabel
                            disabled={!formik.status.editing || !authorizedToEdit}
                            value={false}
                            control={<Radio />}
                            label='No'
                          />
                          <FormControlLabel
                            disabled={!formik.status.editing || !authorizedToEdit}
                            value={null}
                            control={<Radio />}
                            label='None'
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    {(formik.values?.caseDemographics?.pronouncer?.isTeleMetryUsed === true ||
                      formik.values?.caseDemographics?.pronouncer?.isTeleMetryUsed === 'true') && (
                      <>
                        <CaseViewAutocomplete
                          xs={12}
                          sm={4}
                          md={4}
                          authorizedToEdit={authorizedToEdit}
                          fieldName='caseDemographics.pronouncer.pronouncerAgency'
                          options={pronouncerAgencyOptions || []}
                          label='Telemetry Location'
                        />

                        {formik.values?.caseDemographics?.pronouncer?.pronouncerAgency
                          ?.optionSeq === PronouncerAgency_Other && (
                          <CaseViewTextField
                            xs={12}
                            sm={4}
                            md={4}
                            authorizedToEdit={authorizedToEdit}
                            fieldName='caseDemographics.pronouncer.otherPronouncerAgency'
                            label='Other'
                          />
                        )}
                      </>
                    )}

                    {(formik.values?.caseDemographics?.pronouncer?.isTeleMetryUsed === false ||
                      formik.values?.caseDemographics?.pronouncer?.isTeleMetryUsed === 'false') && (
                      <>
                        <CaseViewAutocomplete
                          xs={12}
                          sm={4}
                          md={2}
                          authorizedToEdit={authorizedToEdit}
                          fieldName='caseDemographics.pronouncer.pronouncerLocation'
                          options={pronouncerLocationOptions || []}
                          label='Pronouncer Location'
                        />

                        {formik.values?.caseDemographics?.pronouncer?.pronouncerLocation
                          ?.optionSeq === PronouncerLocation_Other && (
                          <CaseViewTextField
                            xs={12}
                            sm={4}
                            md={2}
                            authorizedToEdit={authorizedToEdit}
                            fieldName='caseDemographics.pronouncer.otherPronouncerLocation'
                            label='Other'
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
            <AddressData
              handleChange={data =>
                formik?.handleChange({
                  target: {
                    value: data,
                    name: 'caseInvestigations.bodyLocation',
                  },
                })
              }
              addresses={formik.values?.caseInvestigations?.bodyLocation || []}
              title='LOCATION OF BODY'
              showAllTypes={true}
              disabled={!formik.status.editing || !authorizedToEdit}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControlLabel
              label='Suspected Drug Toxicity'
              disabled={!formik.status.editing || !authorizedToEdit}
              control={
                <Checkbox
                  id='caseInvestigations.suspectedDrugToxicity'
                  name='caseInvestigations.suspectedDrugToxicity'
                  checked={formik.values?.caseInvestigations?.suspectedDrugToxicity}
                  value={formik.values?.caseInvestigations?.suspectedDrugToxicity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.suspectedDrugToxicity &&
                    Boolean(formik.errors.suspectedDrugToxicity)
                  }
                  helperText={
                    formik.touched.suspectedDrugToxicity && formik.errors.suspectedDrugToxicity
                  }
                />
              }
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControlLabel
              label='Suspected Opioid Death'
              disabled={!formik.status.editing || !authorizedToEdit}
              control={
                <Checkbox
                  id='caseInvestigations.suspectedOpioid'
                  name='caseInvestigations.suspectedOpioid'
                  checked={formik.values?.caseInvestigations?.suspectedOpioid}
                  value={formik.values?.caseInvestigations?.suspectedOpioid}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.suspectedOpioid && Boolean(formik.errors.suspectedOpioid)}
                  helperText={formik.touched.suspectedOpioid && formik.errors.suspectedOpioid}
                />
              }
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControlLabel
              label='Suspected Infectious Disease'
              disabled={!formik.status.editing || !authorizedToEdit}
              control={
                <Checkbox
                  id='caseInvestigations.suspectedInfectiousDisease'
                  name='caseInvestigations.suspectedInfectiousDisease'
                  checked={formik.values?.caseInvestigations?.suspectedInfectiousDisease}
                  value={formik.values?.caseInvestigations?.suspectedInfectiousDisease}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.suspectedInfectiousDisease &&
                    Boolean(formik.errors.suspectedInfectiousDisease)
                  }
                  helperText={
                    formik.touched.suspectedInfectiousDisease &&
                    formik.errors.suspectedInfectiousDisease
                  }
                />
              }
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControlLabel
              label='Show Map'
              control={
                <Checkbox
                  id='showMap'
                  name='showMap'
                  checked={showMap}
                  onChange={handleShowMapCheckboxChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.showMap && Boolean(formik.errors.showMap)}
                  helperText={formik.touched.showMap && formik.errors.showMap}
                />
              }
            />
          </Grid>

          {showMap && (
            <Grid item xs={12}>
              <AddressMap
                addressLine1={
                  formik.values?.caseInvestigations?.bodyLocation[0]?.addressLine1 ?? ''
                }
                addressLine2={
                  formik.values?.caseInvestigations?.bodyLocation[0]?.addressLine2 ?? ''
                }
                city={formik.values?.caseInvestigations?.bodyLocation[0]?.city ?? ''}
                state={formik.values?.caseInvestigations?.bodyLocation[0]?.state?.optionName ?? ''}
                zipcode={formik.values?.caseInvestigations?.bodyLocation[0]?.zipCode ?? ''}
              />
            </Grid>
          )}

          {selectedTab === 0 && (
            <Grid item xs={12}>
              <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
              <Grid item xs={12} pb={2}>
                <Typography variant='subtitle1' color='gray'>
                  <strong>
                    DECEDENT RESIDENCE
                    {!formik.status.editing &&
                      Boolean(
                        formik.values.caseDemographics?.useBodyLocationAddressAsDecedentAddress
                      ) &&
                      ` (same as Location of Body)`}
                  </strong>
                </Typography>
              </Grid>

              <Grid item xs={12} pb={2}>
                <CaseViewSameAsAddressField />
              </Grid>
              <Grid
                component={Collapse}
                in={
                  (formik.status.editing &&
                    !Boolean(
                      formik.values.caseDemographics?.useBodyLocationAddressAsDecedentAddress
                    )) ||
                  !formik.status.editing
                }
                item
                xs={12}
              >
                <AddressData
                  handleChange={data =>
                    formik?.handleChange({
                      target: {
                        value: data,
                        name: 'caseDemographics.decedentAddress',
                      },
                    })
                  }
                  addresses={formik.values?.caseDemographics?.decedentAddress || []}
                  title=''
                  decedentAddressTypes={true}
                  disabled={!formik.status.editing}
                />
              </Grid>
            </Grid>
          )}

          <br />
        </CaseViewFieldsContainer>
      )}

      {selectedTab === 2 && (
        <>
          {/* <Grid item xs={12} mt={2}>
            <Typography variant='subtitle1' color='gray'>
              <strong>Caller (Scene Contact) Details</strong>
            </Typography>
          </Grid> */}

          <Grid item xs={12} md={12} mt={2}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} md={6}>
                <TextField
                  disabled={!formik.status.editing || !authorizedToEdit}
                  fullWidth
                  required
                  label='Last Name'
                  value={
                    formik.values.caseDemographics?.informantList?.find(informant =>
                      Boolean(informant.caseReporter)
                    )?.personLastName || ''
                  }
                  onChange={e => {
                    const informantIndex = formik.values.caseDemographics?.informantList?.findIndex(
                      informant => Boolean(informant.caseReporter)
                    );

                    if (informantIndex > -1) {
                      formik.setFieldValue(
                        `caseDemographics.informantList[${informantIndex}].personLastName`,
                        e.target.value
                      );
                      formik.setFieldValue(
                        `caseDemographics.informantList[${informantIndex}].isChgInd`,
                        1
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!formik.status.editing || !authorizedToEdit}
                  fullWidth
                  required
                  label='First Name'
                  value={
                    formik.values.caseDemographics?.informantList?.find(informant =>
                      Boolean(informant.caseReporter)
                    )?.personFirstName || ''
                  }
                  onChange={e => {
                    const informantIndex = formik.values.caseDemographics?.informantList?.findIndex(
                      informant => Boolean(informant.caseReporter)
                    );

                    if (informantIndex > -1) {
                      formik.setFieldValue(
                        `caseDemographics.informantList[${informantIndex}].personFirstName`,
                        e.target.value
                      );

                      formik.setFieldValue(
                        `caseDemographics.informantList[${informantIndex}].isChgInd`,
                        1
                      );
                    }
                  }}
                />
              </Grid> */}
            </Grid>
          </Grid>

          {/* <MEActionView /> */}
          {Selected_ME_Action !== MEAction.Constants.Disposition.MAID && (
            <>
              <DiscoveryView
                discovery={formik.values?.caseInvestigations?.discovery || []}
                informantList={formik.values?.caseDemographics?.informantList || []}
                handleChange={handleDiscoveryViewChange}
                editing={formik.status.editing}
              />
              <br />
              <SceneExaminedBy />
            </>
          )}

          <Grid item xs={12}>
            <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
            <Typography variant='subtitle1' color='gray'>
              <strong>ADDITIONAL INFORMATION (for Datasheet)</strong>
            </Typography>
            <br />

            <CaseViewFieldsContainer>
              <FormikTextField
                gridProps={{ xs: 12 }}
                authorizedToEdit={authorizedToEdit}
                formikField='caseInvestigations.deathInvestigationAdditionalInformation.initialInfo'
                label='Initial Information Reported'
                multiline
                InputProps={{
                  inputProps: {
                    minRows: 4,
                  },
                }}
              />

              <FormikTextField
                gridProps={{ xs: 12 }}
                authorizedToEdit={authorizedToEdit}
                formikField='caseInvestigations.deathInvestigationAdditionalInformation.investigationInfo'
                label='Investigation'
                multiline
                InputProps={{
                  inputProps: {
                    minRows: 4,
                  },
                }}
              />

              <FormikTextField
                gridProps={{ xs: 12 }}
                authorizedToEdit={authorizedToEdit}
                formikField='caseInvestigations.deathInvestigationAdditionalInformation.witnessFamilyStatement'
                label='Witness/Family Statement'
                multiline
                InputProps={{
                  inputProps: {
                    minRows: 4,
                  },
                }}
              />

              <Grid item xs={12}>
                <FormControlLabel
                  label='Confirmed ID Directly From Source?'
                  control={
                    <Checkbox
                      id='caseInvestigations.deathInvestigationAdditionalInformation.confirmedIdFromSource'
                      name='caseInvestigations.deathInvestigationAdditionalInformation.confirmedIdFromSource'
                      checked={
                        formik?.values?.caseInvestigations?.deathInvestigationAdditionalInformation
                          ?.confirmedIdFromSource
                      }
                      disabled={!formik?.status?.editing || !authorizedToEdit}
                      onChange={event =>
                        formik.setFieldValue(
                          'caseInvestigations.deathInvestigationAdditionalInformation.confirmedIdFromSource',
                          event.target.checked
                        )
                      }
                    />
                  }
                  //sx={{ mt: 5, pr: 5 }}
                />
              </Grid>
            </CaseViewFieldsContainer>
          </Grid>

          <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
          <Typography variant='subtitle1' color='gray'>
            <strong>MEDICAL HISTORY</strong>
          </Typography>
          <br />

          <CaseViewFieldsContainer>
            {/* <CaseViewTextField
              xs={12}
              multiline
              rows={4}
              authorizedToEdit={authorizedToEdit}
              fieldName='caseInvestigations.pastMedicalHistoryNarrative' //todo add this field to the formik and DB the word "Narrative is optional however need to distinguish from the pastMedicalHistory tags field
              label='MEDICAL HX'
            /> */}
            <CaseViewAutocompleteCheckbox
              xs={12}
              lg={
                formik.values?.caseInvestigations?.pastMedicalHistory?.some(
                  option =>
                    option.optionSeq?.toUpperCase() === 'CE56465E-6EEF-417D-B52F-7FB868FE6C0B' // if other
                )
                  ? 6
                  : 12
              }
              multiple
              options={pastMedicalHistoryOptions}
              authorizedToEdit={authorizedToEdit}
              fieldName='caseInvestigations.pastMedicalHistory'
              label='Medical HX Tags'
            />

            <CaseViewTextField
              xs={12}
              lg={6}
              authorizedToEdit={authorizedToEdit}
              multiline
              fieldName='caseInvestigations.pastMedicalHistoryOther'
              label='Other Past Medical History'
              sx={{
                display: formik.values?.caseInvestigations?.pastMedicalHistory?.some(
                  option =>
                    option.optionSeq?.toUpperCase() === 'CE56465E-6EEF-417D-B52F-7FB868FE6C0B'
                )
                  ? 'block'
                  : 'none',
              }}
            />

            <Grid item xs={12}>
              <CrudDataGrid
                rows={formik.values?.caseInvestigations?.medicationHistory ?? []}
                setRows={data => formik.setFieldValue('caseInvestigations.medicationHistory', data)}
                initialColumns={medicationHistoryColumns}
                // disabled={!formik.status?.editing}
                createEntry={createMedicationHistory}
                updateEntry={updateMedicationHistory}
                deleteEntry={deleteMedicationHistory}
                title='Medications'
                idcolumn='medicationHistorySeq'
              />
            </Grid>
            <CaseViewTextField
              xs={12}
              multiline
              rows={4}
              authorizedToEdit={authorizedToEdit}
              fieldName='caseInvestigations.pastSocialHistory'
              label='Social History'
            />
          </CaseViewFieldsContainer>
        </>
      )}

      {selectedTab === 1 && (
        <CaseViewFieldsContainer>
          <Divider component='hr' variant='fullWidth' />
          <Grid item xs={12}>
            <Typography variant='subtitle1' color='gray'>
              <strong>NOK & OTHER CONTACTS</strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <CondensedDataGrid
              columnsVisibility={{
                actions:
                  hasSystemAdminRole ||
                  roleCharacteristicsCheck(user?.specialRoleCharacteristics, 'Investigator'),
              }}
              listresults={filteredInformantList}
              columnsInput={informantcolumns}
              idcolumn='informantSeq'
              gridheight={filteredInformantList.length > 0 ? 'auto' : 130}
              gridcolor='#36454f'
              rowsperpage={5}
              tooltip='Informant List'
              onRowClick={params => handleInformantRowClick(params)}
              toolbarComponent={CustomInformantToolbar}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider component='hr' variant='fullWidth' />
            <Typography variant='subtitle1' color='gray'>
              <strong>IDENTIFICATIONS</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CondensedDataGrid
              listresults={filteredIdentifications}
              columnsInput={identificationscolumns}
              idcolumn='identificationDetailSeq'
              gridcolor='#0288d1'
              gridheight={filteredIdentifications.length > 0 ? 'auto' : 100}
              rowsperpage={5}
              tooltip='Identification List'
              columnsVisibility={{
                actions:
                  hasSystemAdminRole ||
                  roleCharacteristicsCheck(user?.specialRoleCharacteristics, 'Investigator'),
              }}
              onRowClick={params => handleIdentificationRowClick(params)}
              toolbarComponent={CustomIDToolbar}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <Box paddingTop={3}>
              <ExamObjectionsView
                autopsyObjection={formik.values?.caseInvestigations?.autopsyObjection || []}
                informantList={formik.values?.caseDemographics?.informantList || []}
                handleObjectionChange={handleObjectionChange}
                handleMEDetailsChange={handleMEDetailsChange}
                // editing={formik.status.editing}
                editing={1}
              />
            </Box>
          </Grid> */}
        </CaseViewFieldsContainer>
      )}

      {selectedTab === 3 && (
        <CaseViewFieldsContainer>
          {/* <Grid item xs={12}>
            <Typography variant='subtitle1' color='gray'>
              <strong>IDENTIFICATIONS</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CondensedDataGrid
              listresults={filteredIdentifications}
              columnsInput={identificationscolumns}
              idcolumn='identificationDetailSeq'
              gridcolor='#0288d1'
              gridheight={filteredIdentifications.length > 0 ? 'auto' : 100}
              rowsperpage={5}
              tooltip='Identification List'
              columnsVisibility={{
                actions:
                  hasSystemAdminRole ||
                  roleCharacteristicsCheck(user?.specialRoleCharacteristics, 'Investigator'),
              }}
              onRowClick={params => handleIdentificationRowClick(params)}
              toolbarComponent={CustomIDToolbar}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Divider component='hr' variant='fullWidth' />
            <Typography variant='subtitle1' color='gray'>
              <strong>DOCUMENTS</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CaseDocuments caseinfo={formik.values?.caseSummary} />
          </Grid>
        </CaseViewFieldsContainer>
      )}

      {selectedTab === 4 && <OrganDonationView />}

      {selectedTab === 5 && (
        <Grid item xs={12}>
          <Box paddingTop={3}>
            <ExamObjectionsView
              autopsyObjection={formik.values?.caseInvestigations?.autopsyObjection || []}
              informantList={formik.values?.caseDemographics?.informantList || []}
              handleObjectionChange={handleObjectionChange}
              handleMEDetailsChange={handleMEDetailsChange}
              // editing={formik.status.editing}
              editing={1}
            />
          </Box>
        </Grid>
      )}

      <CaseViewFieldsContainer>
        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 0, pb: 0 }} />
          <Typography variant='subtitle1' color='gray'>
            <strong>CASE NOTES</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CaseNotes
            department={
              selectedTab === 3 ? AssignmentTypes.Identifications : AssignmentTypes.Investigations
            }
          />
        </Grid>
      </CaseViewFieldsContainer>

      <CaseViewFieldsContainer></CaseViewFieldsContainer>

      <CaseViewFieldsContainer>
        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
          <Grid container alignItems='center'>
            <Typography variant='subtitle1' color='gray'>
              <strong>CASE ACTIVITY LOG</strong>
            </Typography>
            <FormControlLabel
              control={
                <Switch checked={showCaseLog} onChange={toggleCaseLogVisibility} color='primary' />
              }
              label={showCaseLog ? '' : ''}
              sx={{ ml: 2 }}
            />
          </Grid>
        </Grid>
        {showCaseLog && (
          <Grid item xs={12}>
            <CaseActivityLog />
          </Grid>
        )}
      </CaseViewFieldsContainer>
      <CaseViewFieldsContainer></CaseViewFieldsContainer>

      <InformantDialog
        isInformantDialogOpen={isInformantDialogOpen}
        setIsInformantDialogOpen={setIsInformantDialogOpen}
        selectedInformant={selectedInformant}
        setSelectedInformant={setSelectedInformant}
      />

      <IdentificationDialog
        isIdentificationDialogOpen={isIdentificationDialogOpen}
        setIsIdentificationDialogOpen={setIsIdentificationDialogOpen}
        selectedIdentification={selectedIdentification}
        setSelectedIdentification={setSelectedIdentification}
        handleIdentificationSubmit={handleIdentificationSubmit}
      />
      <ConfirmationModal />
    </form>
  );
}
